
import Vue from "vue";
import { IItem } from "../store/state";
import Stepper from "./Stepper.vue";

// Interfaces for component typechecking!
interface SlideshowEventData {}

interface SlideshowEventMethods {}

interface SlideshowEventComputed {
  buttonText: string;
  currentItem: IItem;
  items: IItem[];
}

interface SlideshowEventProps {}

export default Vue.extend<
  SlideshowEventData,
  SlideshowEventMethods,
  SlideshowEventComputed,
  SlideshowEventProps
>({
  name: "SlideshowEvent",
  methods: {
    setPreviousItemIndex() {
      this.$store.commit({
        type: "setPreviousItemIndex",
        payload: null,
      });
    },
    setNextItemIndex() {
      this.$store.commit({
        type: "setNextItemIndex",
        payload: null,
      });
    },
  },
  computed: {
    buttonText() {
      return this.$store.getters.buttonText;
    },
    currentItem() {
      return this.$store.getters.currentItem;
    },
    items() {
      return this.$store.getters.items;
    },
  },
  components: {
    Stepper,
  },
});
