export interface IState {
  buttonText: string;
  title: string;
  subtitle: string;
  items: IItem[];
  artItems: IArtItems;
  itemIndex: number;
  filterTypeNames: IFilterTypeName[];
  typeOfSlideshow: string;
  endpoints: IEndpoints;
  activeFilter: "current" | "coming";
  isArtLibrary: boolean;
  activateAutomaticPagination: boolean;
  intervalDelay: number;
}
export interface IItem {
  title: string;
  uri: string;
  imageUri: string;
  date: string;
  startTime: string;
  endTime: string;
  location: string;
}
export interface IFilterTypeName {
  name: string;
  displayName: string;
}
export interface IEndpoints {
  getArtLibraryItems: string;
}
export interface ISearchResult {
  items: IItem[];
}

export interface IArtItems {
  current: IItem[];
  coming: IItem[];
}

const defaultState: IState = {
  buttonText: "",
  title: "",
  subtitle: "",
  items: [],
  artItems: {
    current: [],
    coming: [],
  },
  itemIndex: 0,
  filterTypeNames: [],
  typeOfSlideshow: "",
  endpoints: {
    getArtLibraryItems: "",
  },
  activeFilter: "current",
  isArtLibrary: false,
  activateAutomaticPagination: false,
  intervalDelay: 5000
};

export default defaultState;
