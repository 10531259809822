import { Getter } from "vuex";
import { IState } from "./state";

const buttonText: Getter<IState, IState> = (state) => state.buttonText;
const title: Getter<IState, IState> = (state) => state.title;
const items: Getter<IState, IState> = (state) => state.items;
const artItems: Getter<IState, IState> = (state) => state.artItems;
const currentArtItems: Getter<IState, IState> = (state) =>
  state.artItems[state.activeFilter];
const itemIndex: Getter<IState, IState> = (state) => state.itemIndex;
const filterTypeNames: Getter<IState, IState> = (state) =>
  state.filterTypeNames;
const typeOfSlideshow: Getter<IState, IState> = (state) =>
  state.typeOfSlideshow;
const currentItem: Getter<IState, IState> = (state) =>
  state.items[state.itemIndex];
const currentArtItem: Getter<IState, IState> = (state) => {
  return state.artItems[state.activeFilter][state.itemIndex];
};
const activeFilter: Getter<IState, IState> = (state) => state.activeFilter;
const isArtLibrary: Getter<IState, IState> = (state) => state.isArtLibrary;

export default {
  buttonText,
  title,
  items,
  artItems,
  currentArtItems,
  itemIndex,
  filterTypeNames,
  typeOfSlideshow,
  currentItem,
  currentArtItem,
  activeFilter,
  isArtLibrary,
};
