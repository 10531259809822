import { MutationTree, Mutation } from "vuex";
import { IState, IItem } from "./state";
import { ActionPayload } from "./actions";

type MutationHandler<PayloadType> = (
  state: IState,
  payload: ActionPayload<PayloadType>
) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
  Object.assign(state, payload);
};
const setPreviousItemIndexMutation: MutationHandler<IState> = (
  state,
  { payload }
) => {
  let amountOfItems = 0;
  let itemIndex = state.itemIndex;
  if (state.isArtLibrary) {
    amountOfItems = state.artItems[state.activeFilter].length;
  } else {
    amountOfItems = state.items.length;
  }
  state.itemIndex = itemIndex === 0 ? amountOfItems - 1 : itemIndex - 1;
};
const setNextItemIndexMutation: MutationHandler<IState> = (
  state,
  { payload }
) => {
  let amountOfItems = 0;
  let itemIndex = state.itemIndex;
  if (state.isArtLibrary) {
    amountOfItems = state.artItems[state.activeFilter].length;
  } else {
    amountOfItems = state.items.length;
  }
  state.itemIndex = itemIndex === amountOfItems - 1 ? 0 : itemIndex + 1;
};
const setIndexMutation: MutationHandler<number> = (state, { payload }) => {
  state.itemIndex = payload;
};
const setActiveFilterMutation: MutationHandler<"current" | "coming"> = (
  state,
  { payload }
) => {
  state.itemIndex = 0;
  state.activeFilter = payload;
};
const setItemsMutation: MutationHandler<IItem[]> = (state, { payload }) => {
  state.items = payload;
};

const mutations: MutationTree<IState> = {
  setState: setStateMutation,
  setPreviousItemIndex: setPreviousItemIndexMutation,
  setNextItemIndex: setNextItemIndexMutation,
  setIndex: setIndexMutation,
  setActiveFilter: setActiveFilterMutation,
  setItems: setItemsMutation,
};

export default mutations;
