
import Vue from "vue";
import { IFilterTypeName } from "../store/state";
import { setActiveFilter } from "../store/actions";

// Interfaces for component typechecking!
interface SlideshowEventData {}

interface SlideshowEventMethods {
  setActiveFilter(typeName: string): void;
}

interface SlideshowEventComputed {
  filterTypeNames: IFilterTypeName[];
  activeFilter: string;
}

interface SlideshowEventProps {}

export default Vue.extend<
  SlideshowEventData,
  SlideshowEventMethods,
  SlideshowEventComputed,
  SlideshowEventProps
>({
  name: "SlideshowEvent",
  methods: {
    setActiveFilter(typeName: string) {
      this.$store.dispatch(setActiveFilter(typeName));
    },
  },
  computed: {
    filterTypeNames() {
      return this.$store.getters.filterTypeNames;
    },
    activeFilter() {
      return this.$store.getters.activeFilter;
    },
  },
  components: {},
});
