
import Vue from "vue";
import { IItem, IArtItems } from "../store/state";
import Stepper from "./Stepper.vue";
import TypeFilters from "./TypeFilters.vue";

// Interfaces for component typechecking!
interface SlideshowEventData {}

interface SlideshowEventMethods {}

interface SlideshowEventComputed {
  title: string;
  subtitle: string;
  currentArtItem: IItem;
  artItems: IArtItems;
  currentArtItems: IItem[];
  activeFilter: string;
}

interface SlideshowEventProps {}

export default Vue.extend<
  SlideshowEventData,
  SlideshowEventMethods,
  SlideshowEventComputed,
  SlideshowEventProps
>({
  name: "SlideshowEvent",
  methods: {
    setPreviousItemIndex() {
      this.$store.commit({
        type: "setPreviousItemIndex",
        payload: null,
      });
    },
    setNextItemIndex() {
      this.$store.commit({
        type: "setNextItemIndex",
        payload: null,
      });
    },
  },
  computed: {
    title() {
      return this.$store.getters.title;
    },
    subtitle() {
      return this.$store.state.subtitle;
    },
    currentArtItem() {
      return this.$store.getters.currentArtItem;
    },
    artItems() {
      return this.$store.getters.artItems;
    },
    currentArtItems() {
      return this.$store.getters.currentArtItems;
    },
    activeFilter() {
      return this.$store.getters.activeFilter;
    },
  },
  components: {
    Stepper,
    TypeFilters,
  },
});
