
import Vue from "vue";
import { IItem } from "../store/state";
import Stepper from "./Stepper.vue";

// Interfaces for component typechecking!
interface SlideshowEventData {
  intervalId: number
}

interface SlideshowEventMethods {
  setNextItemIndex(): void
  setPreviousItemIndex(): void
  startInterval(): void
  stopInterval(): void
}

interface SlideshowEventComputed {
  currentItem: IItem;
  items: IItem[];
  activateAutomaticPagination: boolean;
}

interface SlideshowEventProps {}

export default Vue.extend<
  SlideshowEventData,
  SlideshowEventMethods,
  SlideshowEventComputed,
  SlideshowEventProps
>({
  name: "SlideshowEvent",
  data() {
    return {
      intervalId: 0
    }
  },
  methods: {
    setPreviousItemIndex() {
      this.$store.commit({
        type: "setPreviousItemIndex",
        payload: null,
      });
    },
    setNextItemIndex() {
      this.$store.commit({
        type: "setNextItemIndex",
        payload: null,
      });
    },
    startInterval() {
      this.intervalId = setInterval(() => {this.setNextItemIndex()}, this.$store.state.intervalDelay);
    },
    stopInterval() {
      clearInterval(this.intervalId)
      this.intervalId = 0;
    },
  },
  mounted() {
    if(this.activateAutomaticPagination) {
      this.startInterval()
    }
  },
  computed: {
    currentItem() {
      return this.$store.getters.currentItem;
    },
    items() {
      return this.$store.getters.items;
    },
    activateAutomaticPagination() {
      return this.$store.state.activateAutomaticPagination
    }
  },
  components: {
    Stepper,
  },
});
