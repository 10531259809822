
import Vue from "vue";

// Interfaces for component typechecking!
interface SlideshowEventData {}

interface SlideshowEventMethods {
  setIndex(index: number): void;
}

interface SlideshowEventComputed {
  itemIndex: number;
  stepperItems: number[];
  itemCount: number
}

interface SlideshowEventProps {
  fromArtLibrary: Boolean;
}

export default Vue.extend<
  SlideshowEventData,
  SlideshowEventMethods,
  SlideshowEventComputed,
  SlideshowEventProps
>({
  name: "SlideshowEvent",
  props: {
    fromArtLibrary: Boolean,
  },
  methods: {
    setIndex(index: number) {
      this.$store.commit({
        type: "setIndex",
        payload: index,
      });
    },
  },
  computed: {
    itemIndex() {
      return this.$store.getters.itemIndex;
    },
    stepperItems() {
      let amountOfItems = 0;
      if (this.fromArtLibrary) {
        amountOfItems = this.$store.getters.currentArtItems.length;
      } else {
        amountOfItems = this.$store.getters.items.length;
      }
      let stepperItems = [];
      for (let i = 0; i < amountOfItems; i++) {
        stepperItems.push(i);
      }
      return stepperItems;
    },
    itemCount() {
      if (this.fromArtLibrary) {
        return this.$store.getters.currentArtItems.length;
      } else {
        return this.$store.getters.items.length;
      }
    }
  },
  components: {},
});
