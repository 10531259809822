
import Vue from "vue";
import { IItem } from "../store/state";
import Stepper from "./Stepper.vue";

// Interfaces for component typechecking!
interface SlideshowSmallData {}

interface SlideshowSmallMethods {}

interface SlideshowSmallComputed {
  currentItem: IItem;
  items: IItem[];
}

interface SlideshowSmallProps {}

export default Vue.extend<
  SlideshowSmallData,
  SlideshowSmallMethods,
  SlideshowSmallComputed,
  SlideshowSmallProps
>({
  name: "SlideshowSmall",
  methods: {
    setPreviousItemIndex() {
      this.$store.commit({
        type: "setPreviousItemIndex",
        payload: null,
      });
    },
    setNextItemIndex() {
      this.$store.commit({
        type: "setNextItemIndex",
        payload: null,
      });
    },
  },
  computed: {
    currentItem() {
      return this.$store.getters.currentItem;
    },
    items() {
      return this.$store.getters.items;
    },
  },
  components: {
    Stepper,
  },
});
