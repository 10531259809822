import { IState } from "./state";
import { ActionTree, Store, ActionContext } from "vuex";
import * as api from "../api";

type PayloadType = "initApp" | "setState" | "setActiveFilter";
export type ActionPayload<ActionData> = {
  type: PayloadType;
  payload: ActionData;
};

type ActionHandler<ActionData> = (
  this: Store<IState>,
  injectee: ActionContext<IState, IState>,
  payload: ActionPayload<ActionData>
) => any;
type ActionCreator<ActionData> = (
  data: ActionData
) => ActionPayload<ActionData>;

// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
  type: "initApp",
  payload: state,
});
export const setActiveFilter: ActionCreator<string> = (payload) => ({
  type: "setActiveFilter",
  payload: payload,
});

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
  commit({
    type: "setState",
    payload,
  });
};
const setActiveFilterAction: ActionHandler<string> = (
  { commit, state },
  { payload }
) => {
  api
    .search(state.endpoints.getArtLibraryItems, {
      selectedFilter: payload,
    })
    .then((res) => {
      commit({
        type: "setActiveFilter",
        payload: payload,
      });
      commit({
        type: "setItems",
        payload: res,
      });
    });
};

const actions: ActionTree<IState, IState> = {
  initApp: initAppAction,
  setActiveFilter: setActiveFilterAction,
};

export default actions;
