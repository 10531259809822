import { ISearchResult } from "../store/state";
import axios from "axios";

const headers = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
};
export interface ISearchParams {
  selectedFilter: string;
}

export const search = (
  searchURL: string,
  params: ISearchParams
): Promise<ISearchResult> => {
  return axios
    .post(searchURL, params, { headers })
    .then((response: any) => {
      return response.data;
    })
    .catch((searchError: any) => {
      console.error({
        searchError,
      });
      return {} as ISearchResult;
    });
};
