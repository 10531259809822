
import Vue from "vue";
import SlideshowArtLibrary from "./components/SlideshowArtLibrary.vue";
import SlideshowEvent from "./components/SlideshowEvent.vue";
import SlideshowSmall from "./components/SlideshowSmall.vue";
import SlideshowStartpage from "./components/SlideshowStartpage.vue";

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
  typeOfSlideshow: string;
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
  computed: {
    typeOfSlideshow() {
      return this.$store.getters.typeOfSlideshow;
    },
  },
  components: {
    SlideshowArtLibrary,
    SlideshowEvent,
    SlideshowSmall,
    SlideshowStartpage,
  },
});
